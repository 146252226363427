<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">艺术家天地</div>
      <div class="list-group p-0">
        <a href="/train" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">艺术家园区</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="text-left h3">艺术作品赏析</div>
<!--      <div class="text-left small">-->
<!--        <span>2021年03月25日</span><span class="pl-md-3 pr-md-3"> / </span><span>Liych</span>-->
<!--        <span class="pl-md-3 pr-md-3"> / </span><span><i class="fa fa-eye" aria-hidden="true"> 209</i></span>-->
<!--      </div>-->
<!--      <div></div>-->
      <div class="row text-left mt-md-5 w-100 small">
        <div class="col-md-3 mb-5 p-0 card" v-for="(decImg, index) in descImgs" :key="index" :src="decImg">
          <div class="card float-left">
            <viewer>
              <img :src=decImg.imageUrl class="w-100" style="height: 140px">
            </viewer>
            <div class="card-footer text-center">
              <p class="card-text small">{{decImg.image}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "artist",
  data () {
    return {
      descImgs: [
        // 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        // 'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        // 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        // 'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        // 'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        // 'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        // 'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
      ]
    }
  },
  mounted() {
    this.getImageList();
  },
  methods: {
    getImageList() {
      this.$http({
        method: 'get',
        url: '/api/cmsReception/getImageByIsOpen'
      }).then((res) =>{
        this.descImgs = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>